import OrderService from 'modules/order/orderService';
import Errors from 'modules/shared/error/errors';
import { i18n } from 'i18n';
import { getHistory } from 'modules/store';
import Message from 'view/shared/message';

const prefix =
  'ORDER_GENERATE_TAG';

const actions = {
  GENERATE_TAG_STARTED: `${prefix}GENERATE_TAG_STARTED`,
  GENERATE_TAG_SUCCESS: `${prefix}GENERATE_TAG_SUCCESS`,
  GENERATE_TAG_ERROR: `${prefix}GENERATE_TAG_ERROR`,

  GENERATE_TAG_ALL_STARTED: `${prefix}GENERATE_TAG_ALL_STARTED`,
  GENERATE_TAG_ALL_SUCCESS: `${prefix}GENERATE_TAG_ALL_SUCCESS`,
  GENERATE_TAG_ALL_ERROR: `${prefix}GENERATE_TAG_ALL_ERROR`,

  doGenerateTag: (dataTags) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GENERATE_TAG_STARTED,
      });

      const response = await OrderService.generateTag(dataTags);
      
      var link = document.createElement('a');
      link.innerHTML = 'Download PDF file';
      link.download = `print-tags-${new Date().toLocaleString()}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + response.body;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch({
        type: actions.GENERATE_TAG_SUCCESS,
      });

      Message.success(i18n('entities.order.generateTag.success'));

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GENERATE_TAG_ERROR,
      });
    }
  },

}

export default actions;