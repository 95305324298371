import {
  Layout as AntLayout,
  Menu as AntMenu,
  Icon,
} from 'antd';
import React, { Component } from 'react';
import SiderWrapper from 'view/layout/styles/SiderWrapper';
import { Link } from 'react-router-dom';
import authSelectors from 'modules/auth/authSelectors';
import { connect } from 'react-redux';
import PermissionChecker from 'modules/auth/permissionChecker';
import actions from 'modules/layout/layoutActions';
import layoutSelectors from 'modules/layout/layoutSelectors';
import routes from 'view/routes';
const { Sider } = AntLayout;

const { SubMenu } = AntMenu;

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
  }

  componentDidMount() {
    this.toggleMenuOnResize();
    window.addEventListener(
      'resize',
      this.toggleMenuOnResize,
    );

  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.toggleMenuOnResize,
    );
  }

  toggleMenuOnResize = () => {
    window.innerWidth < 576
      ? this.hideMenu()
      : this.showMenu();
  };

  selectedKeys(returnName) {
    const url = this.props.url;
    const match = routes.privateRoutes.map(item => {
      return item.menuGroup.item.find((option) => {
        if (option.menu.exact) {
          return url === option.path
        }
  
        return url === option.path || url.startsWith(option.path + '/')
      });
    })

    if (match) {
      return match.map(match => {
        if(match != undefined){
          if(returnName === 'Item'){
            return match.path
          }else if(returnName === 'SubMenu'){
            return match.menuGroup
          }
        }
      })
    }

    return null;
  }

  hideMenu = () => {
    const { dispatch } = this.props;
    dispatch(actions.doHideMenu());
  };

  showMenu = () => {
    const { dispatch } = this.props;
    dispatch(actions.doShowMenu());
  };

  match = (permission) => {
    const permissionChecker = new PermissionChecker(
      this.props.currentUser,
    );
    return permissionChecker.match(permission);
  };

  render() {
    return (
      <SiderWrapper
        style={{
          display: this.props.menuVisible
            ? 'block'
            : 'none',
        }}
      >
        <Sider theme="light" trigger={null}>
          <div className="logo">
              <Link to="/"><img src="/images/logo_h_preta.png"/></Link>
          </div>

          <AntMenu
            theme="light"
            mode="inline"
            selectedKeys={this.selectedKeys('Item')}
            defaultOpenKeys={this.selectedKeys('SubMenu')}
          >
            {routes.privateRoutes.map(item => {
              if(item.menuGroup.isGroup){
               const childrensPermission = item.menuGroup.item.filter((privateRoute) => !!privateRoute.menu)
                .filter((privateRoutes) =>
                  this.match(
                    privateRoutes.permissionRequired,
                  ),
                )
                if(childrensPermission.length !== 0){
                return <SubMenu
                  key={item.menuGroup.label} 
                  title={
                    <span>
                      <Icon type={item.menuGroup.icon} />
                      {item.menuGroup.label}
                    </span>
                  }
                >
                  {
                    item.menuGroup.item.filter((privateRoute) => !!privateRoute.menu)
                    .filter((privateRoutes) =>
                      this.match(
                        privateRoutes.permissionRequired,
                      ),
                    ).map((privateRoute) =>{ 
                      return (
                      <AntMenu.Item key={privateRoute.path}>
                        <Link to={privateRoute.path}>
                          <Icon type={privateRoute.icon} />
                          <span>{privateRoute.label}</span>
                        </Link>
                      </AntMenu.Item>
                    )})
                  }
                </SubMenu>
                }
              }else {
                return item.menuGroup.item.filter((privateRoute) => !!privateRoute.menu)
                .filter((privateRoutes) =>
                  this.match(
                    privateRoutes.permissionRequired,
                  ),
                ).map((privateRoute) => (
                  <AntMenu.Item key={privateRoute.path}>
                    <Link to={privateRoute.path}>
                      <Icon type={privateRoute.icon} />
                      <span>{privateRoute.label}</span>
                    </Link>
                  </AntMenu.Item>
                ))
              }
            })  
            }
          </AntMenu>
        </Sider>
      </SiderWrapper>
    );
  }
}

const select = (state) => ({
  currentUser: authSelectors.selectCurrentUser(state),
  menuVisible: layoutSelectors.selectMenuVisible(state),
});

export default connect(select)(Menu);
